.cancel-seguro-label {
  font-size: 18px; /* Aumenta o tamanho da mensagem */
  margin-top: 15px;
}

.rescisao-panel {
  padding: 20px;
  background-color: #f7f7fa;
  border: 1px solid #e5e5ea;
  border-radius: 6px;
  text-align: left;
}

.rescisao-info {
  font-size: 16px;
  margin-top: 10px;
}

.proprietario-info {
  font-size: 14px;
}

.contrato-info {
  font-size: 16px;
}

.transferencia-subtitulo {
  font-weight: 600;
  font-size: 16px;
}
