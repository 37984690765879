.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5; /* Cor de fundo suave */
}

.login-box {
  padding: 20px;
  border-radius: 8px;
  background-color: #ffffff; /* Cor de fundo do box */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.login-box h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.rs-btn {
  font-size: 16px;
  padding: 10px 20px;
}
