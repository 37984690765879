.imovel-panel {
  padding: 20px;
  background-color: #f7f7fa;
  border: 1px solid #e5e5ea;
  border-radius: 6px;
}

.imovel-titulo {
  font-size: 18px;
  color: #2e5bff;
  margin-bottom: 10px;
}

.imovel-info {
  font-size: 16px;
  margin-top: 10px;
}

.imovel-sem-info {
  color: #999;
  font-style: italic;
}

.list-item {
  display: flex;
  align-items: center;
}

.remove-icon {
  cursor: pointer;
  color: red;
}

.titulo-imovel,
.titulo-locatario,
.titulo-morador,
.titulo-fiador {
  text-align: center;
  margin-bottom: 10px;
  font-size: 1.3rem;
  font-weight: 600;
}

.icon-big {
  font-size: 1.3rem;
}

.icon-link {
  color: blue;
  cursor: pointer;
}

.icon-link:hover {
  text-decoration: underline;
}

.status-label {
  font-weight: bold;
  font-size: 1.2rem; /* Aumenta o tamanho da palavra "Status" */
}

.status-value {
  padding: 4px 8px;
  border-radius: 12px;
  display: inline-block;
  margin-left: 8px;
  font-size: 1.2rem; /* Aumenta o tamanho do status */
}

.status-aberto {
  color: #00bfff;
  background-color: rgba(0, 191, 255, 0.2);
}

.status-ganhou,
.status-concluir {
  color: #28a745;
  background-color: rgba(40, 167, 69, 0.2);
}

.status-perdeu {
  color: #dc3545;
  background-color: rgba(220, 53, 69, 0.2);
}

.status-andamento {
  color: #ffc107;
  background-color: rgba(255, 193, 7, 0.2);
}

.button-spacing {
  margin-right: 10px; /* Adiciona espaço à direita do botão */
}

.modal-title {
  font-size: 24px; /* Aumenta o tamanho do título */
  font-weight: bold; /* Deixa o texto em negrito */
}

.modal-message {
  font-size: 18px; /* Aumenta o tamanho da mensagem */
  font-weight: bold; /* Deixa o texto em negrito */
}
