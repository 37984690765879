:root {
  --primary: #333967;
  --dark: #0d1227;
  --danger: #ff3333;
  --success: #6aea77;
  --white: #ebebeb;
}

#root {
  height: 100%;
  width: 100%;
  position: fixed;
}

header {
  background-color: var(--primary);
}

header > div {
  cursor: pointer;
  transition: 0.5s;
  padding: 10px 20px;
  text-align: right;
}

header > div:hover {
  background-color: rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}

header img {
  width: 50px;
  border-radius: 100%;
  margin-left: 15px;
}

header span:not(.mdi) {
  margin-bottom: -10px;
}

header span.mdi {
  font-size: 20px;
  margin-left: 10px;
}

header small {
  display: block;
  margin-top: -20px;
}

.sidebarHeader {
  background-color: #ebebeb;
  border-radius: 3px 3px 0 0;
  font-size: 15px;
  font-weight: 600;
  width: 100%;
  opacity: 0.6;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 5px;
}

.sidebarLast {
  border-bottom: 1px solid;
}

sidebar {
  background-color: var(--dark);
}

sidebar ul li {
  list-style: none;
  width: 100%;
}

sidebar ul li a {
  width: 100%;
  color: #fff;
  opacity: 0.6;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 12.5px 5px;
  transition: 0.5s;
}

sidebar ul li a.active {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.3);
}

sidebar ul li a:visited,
sidebar ul li a:focus {
  color: #fff;
  text-decoration: none !important;
}

sidebar ul li a:hover {
  opacity: 1;
  cursor: pointer;
  transition: 0.5s;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.3);
}

sidebar ul li span.mdi {
  font-size: 25px;
  margin: 0 15px;
}

sidebar ul li a text {
  font-size: 14px;
}

.rs-checkbox-control {
  background-color: white;
}

.contentHeader {
  border-radius: 3px 3px;
  padding-left: 10px;
  padding-right: 10px;
  height: 40px;
  background-color: var(--white);
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.1); /* Sombra suave */
}

.rs-nav-item,
.rs-form-control-label,
.rs-checkbox-label {
  font-size: medium;
  font-weight: bold;
}

.rs-nav-tabs {
  background-color: #c4d9eb;
  margin-bottom: 20px;
}

.rs-nav-item {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.rs-form-control-wrapper
  :not(
    [name="tituloAnuncio"],
    [name="endereco.rua"],
    [name="fotos"],
    .rs-uploader,
    .rs-uploader-trigger,
    .rs-uploader-trigger-btn,
    .rs-uploader-file-items,
    .rs-uploader-picture,
    .rs-uploader-draggable,
    .rs-uploader-trigger,
    .rs-uploader-trigger-customize
  ) {
  max-width: 200px !important;
}

.rs-nav-horizontal {
  white-space: normal !important;
}

.rs-nav-item {
  flex: 1 0 auto; /* ou outro valor que faça sentido para seu design */
}

.subContent {
  border-radius: 0px 0px 3px 3px;
  padding: 10px;
  background-color: rgba(235, 235, 235, 0.4);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra suave */
}

.formSpacing .rs-form-group {
  margin-bottom: 20px; /* Espaçamento entre os campos */
}
.btnContent {
  margin-top: auto;
}

.cotent {
  padding: 20px;
}

.fieldsContent {
  display: flex;
  flex-wrap: wrap;
}

.fieldsContent > div {
  flex: 50%;
}

.fieldsContent label {
  font-weight: 500;
  color: var(--primary);
}

.fieldsContent text {
  font-size: small;
  color: var(--primary);
}

.fieldsContent input {
  border-radius: 3px;
  width: 0;
  min-width: 95%;
}

.btn {
  background-color: var(--primary);
}

.warning-row {
  font-weight: bold;
  color: red;
}
